import { useCallback, useRef, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import HtvApi from "../../../htv-api/HtvApi";
import FormTextField from "../../../layouts/main/components/Form/FormTextField";
import FormChoice from "../../../layouts/main/components/Form/FormChoice";
import {GlobalState} from "../../../state/GlobalState";
import {ErrorHandling} from "../../../helpers/error-handling";
import ResolvedVideoTile from "./ResolvedVideoTile";


const LoadVideoForm = ({onVideoResolve}) => {
    
    let {id} = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [videoData, setVideoData] = useState({
        loaded: false,
        title: '',
        description: '',
    });
    useEffect(() => {
        HtvApi.Videos.Get(id).then((video) => {
            setVideoData({
                loaded: true,
                title: video.title,
                description: video.description,
                slug: video.slug,
                status: video.status,
                categories: video.categories.map(category => category.id),
                identifier: video.sourceIdentifier,
                source: video.source,
                id: video.id
            });
        }).catch(error => {
            ErrorHandling.GlobalStateError(error);
        });
    }, [id]);



    const data = useRef({
        source: 'vimeo',
        identifier: '',
    });


    const onSubmit = (event) => {
        
        setIsLoading(true);
        event.preventDefault();
        HtvApi.Videos.ResolveExternalVideo(data.current.source, data.current.identifier).then(response => {
            GlobalState.clearMessages();
            response.source = data.current.source;
            response.identifier = data.current.identifier;
            response.id = videoData.id;
            onVideoResolve(response);
        }).catch(error => {
            setIsLoading(false);
            ErrorHandling.GlobalStateError(error);
        });
        

    };

    const loader = isLoading ? (<div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin" /></div>) : '';


    return (
        
        <div className="row">

            <div className="col-12">
            <ResolvedVideoTile id={id} videoData={videoData} />
                
                <div className="card card-success">
                    <div className="card-header">
                        <h3 className="card-title">Select video from a connected platform</h3>
                        
                    </div>
                    <form className="form-horizontal" onSubmit={onSubmit}>
                    <div className="card-body">
                        </div>
                        <div className="card-body">
                            <FormChoice
                                name="platform"
                                label="Platform"
                                options={{
                                    vimeo: {
                                        label: 'Vimeo',
                                        color: 'blue',
                                        icon: 'fab fa-vimeo',
                                    },
                                    youtube: {
                                        label: 'YouTube',
                                        color: 'red',
                                        icon: 'fab fa-youtube',
                                    },
                                }}
                                initialValue={data.current.source}
                                onChange={useCallback(value => data.current.source = value, [])}
                            />
                            <FormTextField
                                label="Video identifier"
                                onChange={useCallback(value => data.current.identifier = value, [])}
                            />
                        </div>
                        <div className="card-footer">
                            <button
                                type="submit"
                                className="btn btn-success"
                            >
                                Update video information
                            </button>
                            <Link to="/videos"
                                type="button"
                                className="btn btn-default float-right"
                            >
                                Cancel
                            </Link>
                        </div>
                    </form>
                    {loader}
                </div>
            </div>
        </div>
    );
}

export default LoadVideoForm;
