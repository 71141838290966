import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from 'mobx-react';

import HtvApi from "../../../htv-api/HtvApi";
import LoadVideoForm from "./LoadVideoForm";
import WithMainLayoutPage from "../../WithMainLayoutPage";
import { GlobalState } from "../../../state/GlobalState";
import { ErrorHandling } from "../../../helpers/error-handling";


const replaceVideo = (id, source, identifier, title) => {
    return HtvApi.Videos.ReplaceSource(
        id,
        source,
        identifier
    ).then((response) => {
        GlobalState.setSuccess('Successful operation!', 'Video ' + title + ' updated successfully!');
        return response;
    }).catch(error => {
        ErrorHandling.GlobalStateError(error);
        throw error;
    });
};

const VideoReplacePage = observer(() => {
    const navigate = useNavigate();


    const [resolvedVideo, setResolvedVideo] = useState(null);

    useEffect(() => {
        GlobalState.setPageTitle('Replace video source');
        GlobalState.setLayoutPageTitle('Replace video source');
    }, []);

    const onVideoResolve = (videoData) => {
        setResolvedVideo(videoData);
    };


    useEffect(() => {
        let isMounted = true;
        if (resolvedVideo) {
            replaceVideo(resolvedVideo.id, resolvedVideo.source, resolvedVideo.identifier, resolvedVideo.title)
            .then((response) => {
                if (isMounted) {
                    onVideoResolve(response);
                    navigate('/videos' , {state: {keepMessages: true}});
                }
            });
        }
        return () => {
            isMounted = false;
        };
    }, [resolvedVideo]);

    return (
        <LoadVideoForm onVideoResolve={onVideoResolve} />
    );
});

export default WithMainLayoutPage(VideoReplacePage);
