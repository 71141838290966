import {useCallback, useRef, useState} from "react";
import {Link} from "react-router-dom";
import HtvApi from "../../../htv-api/HtvApi";
import FormTextField from "../../../layouts/main/components/Form/FormTextField";
import FormChoice from "../../../layouts/main/components/Form/FormChoice";
import {GlobalState} from "../../../state/GlobalState";
import {ErrorHandling} from "../../../helpers/error-handling";

const LoadVideoForm = ({onVideoResolve}) => {
    const [isLoading, setLoading] = useState(false);

    const data = useRef({
        source: 'vimeo',
        identifier: '',
    });

    const onSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        HtvApi.Videos.ResolveExternalVideo(data.current.source, data.current.identifier).then(response => {
            GlobalState.clearMessages();
            response.source = data.current.source;
            response.identifier = data.current.identifier;
            onVideoResolve(response);
        }).catch(error => {
            setLoading(false);
            ErrorHandling.GlobalStateError(error);
        });
    };

    const loader = isLoading ? (<div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin" /></div>) : '';

    return (
        <div className="row">
            <div className="col-12">
                <div className="card card-success">
                    <div className="card-header">
                        <h3 className="card-title">Select video from a connected platform</h3>
                    </div>
                    <form className="form-horizontal" onSubmit={onSubmit}>
                        <div className="card-body">
                            <FormChoice
                                name="platform"
                                label="Platform"
                                options={{
                                    vimeo: {
                                        label: 'Vimeo',
                                        color: 'blue',
                                        icon: 'fab fa-vimeo',
                                    },
                                    youtube: {
                                        label: 'YouTube',
                                        color: 'red',
                                        icon: 'fab fa-youtube',
                                    },
                                }}
                                initialValue={data.current.source}
                                onChange={useCallback(value => data.current.source = value, [])}
                            />
                            <FormTextField
                                label="Video identifier"
                                onChange={useCallback(value => data.current.identifier = value, [])}
                            />
                        </div>
                        <div className="card-footer">
                            <button
                                type="submit"
                                className="btn btn-success"
                            >
                                Load video information
                            </button>
                            <Link to="/videos"
                                type="button"
                                className="btn btn-default float-right"
                            >
                                Cancel
                            </Link>
                        </div>
                    </form>
                    {loader}
                </div>
            </div>
        </div>
    );
}

export default LoadVideoForm;
